<template>
  <div class="depositConfirm">
     <div class="bg"></div>
     <div class="content">
             <headbox title="交押金" :isbackPath="true"  path="pricingDeposit"  bgColor="#f83637" iswhite="true"></headbox>
             <div class="topBg"></div>
             <div class="boxTips">
                    <div class="tipIcon">
                            <div class="tipText">
                                <h2>提交成功</h2>
                                <!-- <p>订单号：XXXXXXXXXXXX</p> -->
                            </div>
                    </div>
                     <div class="seeBtn btn" @click="seeOrder">查看订单</div>
                     <div class="backBtn btn" @click="back">返回首页</div>

             </div>
     </div>
  </div>
</template>

<script>
import headbox from '@/components/head.vue'
export default {
    components:{
        headbox
    },
    data() {
        return {
            
        }
    },
    methods:{
        back(){
         this.$router.push({
                   name:'person'
            })
        },
        seeOrder(){
            this.$router.push({
                   name:'pricingDeposit'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.topBg {
  position: relative;
  height: 360px;
  background: url('~@/assets/images/bg@2x.png') no-repeat;
  background-size: 100% 100%;
}
.boxTips{
    width: 100%;
    position: absolute;
    top: 100px;
    left: 0;
    padding: 0px 20px;
    .tipIcon{
            height: 824px;
            background: url('~@/assets/images/pic_deposit_success@2x.png')no-repeat;
            background-size: 100%;
            padding-top:280px ;
            .tipText{
                width: 400px;
                margin: 0 auto;
                text-align: center;
                h2{
                   color: #242424;
                   font-weight: 500;
                   font-size: 36px;
                }
                p{
                    color: #999999;
                    font-size: 28px;
                    margin-top:12px;
                }
            }

    }
    .btn{
        border-radius: 50px;
        text-align: center;
        color: #fff;
        height: 86px;
        line-height: 86px;
        font-size: 32px;
        margin-top: 40px;
    }
    .seeBtn{
        background: #FF2D2E;
    }
    .backBtn{
        color: #999999;
        background: #F5F5F5;
        border: 1px solid #E9E9E9;
    }
}
.bg{
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.content{
    position: relative;
    z-index: 1;
}
</style>